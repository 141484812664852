<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
          <c-datepicker
            :range="true"
            defaultStart="-3M"
            defaultEnd="0M"
            name="requestPeriod"
            label="신청기간"
            v-model="searchParam.requestPeriod"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="HEA_CLOSED_SPACE_EQUIP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="equipRentStatusCd"
            label="진행상태"
            v-model="searchParam.equipRentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="신청부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            name="vendorCd"
            label="신청업체"
            v-model="searchParam.vendorCd"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업장비 대여신청 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openNew" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'closedSpaceEquip',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        vendorCd: '',
        equipRentStatusCd: null,
        requestPeriod: [],
        listSearchFlag: 'Y'
      },
      gubunItems: [
        { code: 'D', codeName: '부서' },
        { code: 'V', codeName: '업체' },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'sopEquipmentRentId', },
          { index: 1, colName: 'sopEquipmentRentId', },
          { index: 2, colName: 'sopEquipmentRentId', },
          { index: 3, colName: 'sopEquipmentRentId', },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'requestUserName',
            field: 'requestUserName',
            label: '신청자',
            style: 'width:130px',
            align: 'center',
            type: 'link'
          },
          {
            name: 'equipRentStatusName',
            field: 'equipRentStatusName',
            label: '진행단계',
            style: 'width:90px',
            align: 'center',
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '관련 작업허가서',
            style: 'width:90px',
            align: 'center',
            type: 'link'
          },
          {
            name: 'request',
            field: 'request',
            label: '대여 신청',
            align: 'center',
            child: [
              {
                name: 'requestEquipmentName',
                field: 'requestEquipmentName',
                label: '신청품목',
                style: 'width:150px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'requestCount',
                field: 'requestCount',
                label: '신청수량',
                style: 'width:90px',
                align: 'right',
                sortable: true,
              },
              {
                name: 'requestRentPeriod',
                field: 'requestRentPeriod',
                label: '신청기간',
                style: 'width:130px',
                align: 'center',
              },
            ]
          },
          {
            name: 'rentReturn',
            field: 'rentReturn',
            label: '대여 및 반납',
            align: 'center',
            child: [
              {
                name: 'rentEquipmentName',
                field: 'rentEquipmentName',
                label: '대여품목',
                style: 'width:150px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'rentCount',
                field: 'rentCount',
                label: '대여수량',
                style: 'width:90px',
                align: 'right',
                sortable: true,
              },
              {
                name: 'rentPeriod',
                field: 'rentPeriod',
                label: '대여기간',
                style: 'width:130px',
                align: 'center',
              },
              {
                name: 'returnFlag',
                field: 'returnFlag',
                label: '반납여부',
                style: 'width:80px',
                align: 'center',
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.gives.list.url;
      // code setting
      // list setting 
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openNew() {
      this.linkClick(null, { name: 'requestUserName' });
    },
    linkClick(row, col) {
      if (col.name === 'requestUserName') {
        this.popupOptions.title = '작업장비 신청/대여/반납 상세'; 
        this.popupOptions.param = {
          sopEquipmentRentId: row ? row.sopEquipmentRentId : '',
        };
        this.popupOptions.target = () => import(`${'./closedSpaceEquipDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else {
        this.popupOptions.title = 'LBL0000938';
        this.popupOptions.param = {
          sopWorkPermitId: row ? row.sopWorkPermitId : '',
          permitTypeCd: 'SPT0000001',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>